import React, {useEffect, useState} from "react";
import "./singleCard.scss";
import {getImages, getImagesPass} from "../../web3/contractInteraction";
import axios from "axios";
import {MdWatchLater} from "react-icons/md";

const SingleCard = ({ userAddress, token, selected, setSelected,collectionValue }) => {
  const [imageURL, setImageURL] = useState('');
  const routName = window.location.pathname
  const handleClick = (code) => {
    const select = selected;
    if (select.includes(code)) {
      select.splice(
        select.findIndex((e) => e === code),
        1
      );
    } else {
      select.push(code);
    }
    setSelected([...select]);
  };

  useEffect(()=>{
    if(userAddress !== undefined){
      getTokensImages()
    }
  },[userAddress])

  const getTokensImages =async () => {
    if(routName === '/unstaked'){
      const url = await getImages(collectionValue, token.id);
      const { data } = await axios.get(`https://oxyproxy.0xytocin.online/${url}`);
      setImageURL(data)
    }else if(routName === '/staked'){
      const url = await getImagesPass(collectionValue, token.id);
      const { data } = await axios.get(`https://oxyproxy.0xytocin.online/${url}`)
      setImageURL(data)
    }
  }

  const convertTime = (time) => {    
    if(time > 0) {
        const daysDifference = Math.floor(time/60/60/24);
        time -= daysDifference*60*60*24
    
        const hoursDifference = Math.floor(time/60/60);
        time -= hoursDifference*60*60
    
        const minutesDifference = Math.floor(time/60);
        time -= minutesDifference*60

        return (daysDifference + 'd ' + hoursDifference + 'h ' + minutesDifference + 'm');
    }
    else return ('0d 0h 0m');
}

  return (
    <>
      <div
        className={`single_card ${routName === '/unstaked' &&selected.includes(token.id)&& "selected" ||routName === '/staked' &&selected.includes(token.id) && "selected"} ${
          userAddress === undefined && "disabled"
        }`}
        onClick={() => {
          routName === '/unstaked' ? handleClick(token.id):handleClick(token.id)
        }}
      >
        <div className="img_div">
          {userAddress !== undefined && (
            <>
            <img
              src={imageURL?.image}
              alt=''
            />
            {/* {routName === '/staked' && <div className='timer_div'><MdWatchLater className='icon'/>{token.remainTime} </div>} */}
            </>
          )}
        </div>
        <div className="card_id_div">
          {userAddress !== undefined ? (
            routName === '/unstaked' && <span className={`${selected.includes(token) && "selected_span"}`}>
              #{token.id}
            </span> ||
            routName === '/staked' && <span className={`${selected.includes(token.id) && "selected_span"}`}>
              #{token.id}
            </span>

          ) : (
            <span>XXXX</span>
          )}
        </div>

        <div className="stake_info">
          <p><MdWatchLater /> Time Staked</p>
          {routName === '/staked' &&
            <div className="time">
              <p>Current</p>
              <p>{convertTime(token.currentNapTime)}</p>
            </div>
          }
          <div className="time">
            <p>Total</p>
            <p>{convertTime(token.totalNapTime)}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleCard;
