import React from 'react';

import "./header.scss";

import hkcLogo from "../../assets/hkc-logo.png";
import openseaLogo from "../../assets/opensea-logo.png";
import twitterLogo from "../../assets/twitter-logo.png";
import discordLogo from "../../assets/discord-logo.png";
import magicdenLogo from "../../assets/magiceden-logo.png";

export default function Header() {
  return (
    <div className="header-container">
      <a href="https://opensea.io/collection/hiddenkittencity"><img className="social-logo" src={openseaLogo} alt="OpenSea" /></a>
      <a href="https://twitter.com/HiddenKittenCTY"><img className="social-logo" src={twitterLogo} alt="Twitter" /></a>
      <img className="hkc-logo" src={hkcLogo} alt="Hidden Kitty City" />
      <a href="http://discord.gg/hiddenkittencity"><img className="social-logo" src={discordLogo} alt="Discord" /></a>
      <a href="https://magiceden.io/collections/eth/0x592f6585dcaaf7524a7c5b17e9c1c80a917ac94c"><img className="social-logo" src={magicdenLogo} alt="Magic Den" /></a>
    </div>
  )
}
