import React from "react";
import "./navbar.scss";
import { useNavigate } from "react-router-dom";

const Navbar = ({ userAddress, nfts, passes }) => {
  const routeName = window.location.pathname;
  const navigate = useNavigate();

  const navigateToUnstaked = () => {
    navigate("/unstaked");
  };

  const navigateToStaked = () => {
    navigate("/staked");
  };
  
  const handleRouteChange = () => {
    if (routeName === "/unstaked") {
      navigate("/staked");
    } else if (routeName === "/staked") {
      navigate("/unstaked");
    }
  };
  return (
    <>
      <div className="navbar_main_div">
        <button
          onClick={navigateToUnstaked}
          disabled={!userAddress}
          className={`route_btn ${
            userAddress !== undefined &&
            routeName === "/unstaked" &&
            "border_class"
          }`}
        >
          {userAddress !== undefined && "Unstaked"}
          <span>{userAddress !== undefined && `(${nfts?.length})`} </span>
        </button>
        <button
          onClick={navigateToStaked}
          disabled={!userAddress}
          className={`route_btn ${
            userAddress !== undefined &&
            routeName === "/staked" &&
            "border_class"
          }`}
        >
          {userAddress !== undefined && "Staked"}
          <span> {userAddress !== undefined && `(${passes?.length})`}</span>
        </button>
      </div>
    </>
  );
};

export default Navbar;
