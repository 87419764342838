import React, {useEffect, useState} from "react";
import "../unstaked/unstaked.scss";
import Logo from "../../components/logo/Logo";
import Navbar from "../../components/navbar/Navbar";
import SingleCard from "../../components/singleCard/SingleCard";
import Collection from "../../components/collection/Collection";
import Connect from "../../components/connect/Connect";
import {
  isApprovedForAllStaked,
  setApprovalForAllStaked,
  unstakeKitties,
  balanceOfStaked,
  tokenOfOwnerByIndex,
  totalSupply
} from "../../web3/contractInteraction";
import loader from "../../assets/loader.gif";
import configs from "../../utils/config.json";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Staked = ({
                  setProvider,
                  provider,
                  toastOptions,
                  userAddress,
                  setUserAddress,
                  getAllTokens,
                  allCollections,
                  passes,
                  selected,
                  setSelected,
                  setCollectionValue,
                  nfts,
                  collectionValue,
                  loading,
                  setLoading,
                  chainId,
                  setChainId,
                  handleSetCollections,
                  approvalPending,
                  setApprovalPending,
                  setTransactionPending,
                  transactionPending
                }) => {
  const [isApproved, setIsApproved] = useState(false);

  useEffect(() => {
    handleSetCollections()
    setSelected([])
    if (userAddress) {
      handleCheckApproval()
      getAllTokens()
    }
  }, [userAddress, collectionValue, chainId]);

  const handleCheckApproval = async () => {
    const _isApproved = await isApprovedForAllStaked(collectionValue, userAddress);
    setIsApproved(_isApproved);
  }

  const handleUnstake = async () => {
    setLoading(true);
    let res;
    setTransactionPending(true)
    if (collectionValue === "1") {
      res = await unstakeKitties(configs.nft1Address, selected);
    } else if (collectionValue === "2") {
      res = await unstakeKitties(configs.nft2Address, selected);
    } else if (collectionValue === "3") {
      res = await unstakeKitties(configs.nft3Address, selected);
    }
    setTransactionPending(false)
    getAllTokens()
    setSelected([]);
    if (res?.hash) {
      toast("Your transaction was successful!", toastOptions);
    } else {
      if(res === 'user rejected transaction '){
        toast('You rejected the transaction.', toastOptions);
      }else {
        toast('Sorry, something went wrong.', toastOptions);
      }
    }
  };

  const handleUnstakeAll = async () => {
    setLoading(true);
    setTransactionPending(true);
    const tokenIds = passes.map(pass => pass.id);
    let res;
    if (collectionValue === "1") {
      res = await unstakeKitties(configs.nft1Address, tokenIds);
    } else if (collectionValue === "2") {
      res = await unstakeKitties(configs.nft2Address, tokenIds);
    } else if (collectionValue === "3") {
      res = await unstakeKitties(configs.nft3Address, tokenIds);
    }
    setTransactionPending(false)
    getAllTokens()
    setSelected([]);
    if (res?.hash) {
      toast("Your transaction was successful!", toastOptions);
    } else {
      if(res === 'user rejected transaction '){
        toast('You rejected the transaction.', toastOptions);
      }else {
        toast('Sorry, something went wrong.', toastOptions);
      }
    }
  };

  const handleSetApproval = async () => {
    setLoading(true)
    setApprovalPending(true)
    const approval = await setApprovalForAllStaked(collectionValue);
    setApprovalPending(false)
    setLoading(false)
    handleCheckApproval()
  }

  return (
    <>
      <ToastContainer/>
      <div className="middle_main_div">
        <div className="left_column_div">
          {/* <Logo/> */}
          {userAddress !== undefined && (
            <Collection
              allCollections={allCollections}
              collectionValue={collectionValue}
              setCollectionValue={setCollectionValue}
              loading={loading}
            />
          )}
          <Connect
            isApproved={isApproved}
            setProvider={setProvider}
            provider={provider}
            userAddress={userAddress}
            setUserAddress={setUserAddress}
            loading={loading}
            passes={passes}
            nfts={nfts}
            handleUnstake={handleUnstake}
            handleUnstakeAll={handleUnstakeAll}
            setSelected={setSelected}
            selected={selected}
            chainId={chainId}
            setChainId={setChainId}
            handleSetApproval={handleSetApproval}
            toastOptions={toastOptions}
            approvalPending={approvalPending}
            transactionPending={transactionPending}
          />
        </div>
        <div className="right_column_div">
          <Navbar userAddress={userAddress} passes={passes} nfts={nfts}/>
          <div className="card_display_div">
            {loading ? (
              <div className="loader_img_div">
                <img src={loader} alt="loading..."/>
              </div>
            ) : (
              <div
                className='card_scroll_div'
              >
                {userAddress !== undefined
                  ? passes.length > 0 ? passes.map((token,i) => (
                    <SingleCard
                      userAddress={userAddress}
                      key={i}
                      token={token}
                      selected={selected}
                      setSelected={setSelected}
                      collectionValue={collectionValue}
                    />
                  )) : <div className="loader_img_div">
                    No NFTs here
                  </div>
                  : <div className="loader_img_div">
                    Connect your wallet to see your NFTs
                  </div>}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Staked;
