import React, {useState} from "react";
import "./App.scss";
import {Route, Routes, Navigate} from "react-router-dom";
import Unstaked from "./pages/unstaked/Unstaked";
import Staked from "./pages/staked/Staked";
import {
  balanceOfstaked,
  getCollectionsName, getNapTime,
  nftcall,
  tokenOfOwnerByIndex,
  totalSupply,
} from "./web3/contractInteraction";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header/Header";
import Terms from "./components/Terms/Terms";

const App = () => {
  const [userAddress, setUserAddress] = useState();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [passes, setPasses] = useState([]);
  const [nfts, setNfts] = useState([]);
  const [allCollections, setAllCollections] = useState([]);
  const [collectionValue, setCollectionValue] = useState("1");
  const [chainId, setChainId] = useState("");
  const [provider, setProvider] = useState(null);
  const [transactionPending, setTransactionPending] = useState(false);
  const [approvalPending, setApprovalPending] = useState(false);

  const handleSetCollections = async () => {
    const allCollections = await getCollectionsName();
    setAllCollections(allCollections);
  };

  const toastStyles = {
    textAlign: "center",
    borderRadius: "13px",
    color: "#FFFFFF",
    width: "400px",
    border: "1px solid rgb(249 249 249 / 0.4)",
    fontSize: "16px",
    fontFamily: "'Silom', sans-serif",
  };

  const toastOptions = {
    position: "bottom-left",
    type: "success",
    closeButton: false,
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: false,
    theme: "dark",
    icon: false,
    style: toastStyles,
  };

  const handleGetNfts = async () => {
    setLoading(true);
    setNfts([])

    const nftData = [];

    const totalTokens = await totalSupply(collectionValue);
    const tokenIds = await nftcall(provider, collectionValue, totalTokens);
    const time = await getNapTime(collectionValue, tokenIds);

    for (let i = 0; i < tokenIds.length; i++) {
      nftData.push({
        id: tokenIds[i],
        totalNapTime: time ? time[i].total : 0,
        currentNapTime: time ? time[i].current : 0
      })
    }
    setNfts(nftData);
  };

  const handleGetStakedPasses = async () => {
    setLoading(true);
    setPasses([]);
    
    const indexId = await balanceOfstaked(collectionValue, userAddress);
    const tokenIds = [];
    const passData = [];

    for (let i = 0; i < indexId; i++) {
      const index = await tokenOfOwnerByIndex(collectionValue, userAddress, i);
      tokenIds.push(index);
    }
    const time = await getNapTime(collectionValue, tokenIds);
    
    for (let i = 0; i < indexId; i++) {
      passData.push({
        id: tokenIds[i],
        totalNapTime: time[i].total,
        currentNapTime: time[i].current
      })
    }
    setPasses(passData);

    // function msToTime(duration) {
    //   let a = Math.floor((duration) / (1000 * 60 * 60 * 24))
    //   let milliseconds = parseInt((duration % 1000) / 100),
    //     minutes = Math.floor((duration / (1000 * 60)) % 60),
    //     hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    //   hours = (hours < 10) ? "0" + hours : hours;
    //   minutes = (minutes < 10) ? "0" + minutes : minutes;
    //   a = (a < 10) ? "0" + a : a;
    //   return a + "d " + hours + "h " + minutes + "m";
    // }

    // function dhm(t) {
    //   var cd = 24 * 60 * 60 * 1000,
    //     ch = 60 * 60 * 1000,
    //     d = Math.floor(t / cd),
    //     h = Math.floor((t - d * cd) / ch),
    //     m = Math.round((t - d * cd - h * ch) / 60000),
    //     pad = function (n) {
    //       return n < 10 ? '0' + n : n;
    //     };
    //   if (m === 60) {
    //     h++;
    //     m = 0;
    //   }
    //   if (h === 24) {
    //     d++;
    //     h = 0;
    //   }
    //   return [d, pad(h), pad(m)].join(':');
    // }    
  };

  const getAllTokens = async () => {
    await handleGetNfts();
    await handleGetStakedPasses();
    setLoading(false)
  }

  return (
    <>
      <ToastContainer/>
      <Header />
      <div className="main_page_div">
        <Routes>
          <Route
            path="unstaked"
            element={
              <Unstaked
                setProvider={setProvider}
                toastOptions={toastOptions}
                userAddress={userAddress}
                setUserAddress={setUserAddress}
                getAllTokens={getAllTokens}
                allCollections={allCollections}
                loading={loading}
                setLoading={setLoading}
                passes={passes}
                nfts={nfts}
                collectionValue={collectionValue}
                setCollectionValue={setCollectionValue}
                selected={selected}
                setSelected={setSelected}
                setAllCollections={setAllCollections}
                provider={provider}
                chainId={chainId}
                setChainId={setChainId}
                handleSetCollections={handleSetCollections}
                approvalPending={approvalPending}
                setApprovalPending={setApprovalPending}
                transactionPending={transactionPending}
                setTransactionPending={setTransactionPending}
              />
            }
          />
          <Route
            path="staked"
            element={
              <Staked
                setProvider={setProvider}
                toastOptions={toastOptions}
                userAddress={userAddress}
                setUserAddress={setUserAddress}
                getAllTokens={getAllTokens}
                allCollections={allCollections}
                loading={loading}
                setLoading={setLoading}
                nfts={nfts}
                passes={passes}
                collectionValue={collectionValue}
                setCollectionValue={setCollectionValue}
                selected={selected}
                setSelected={setSelected}
                chainId={chainId}
                setChainId={setChainId}
                handleSetCollections={handleSetCollections}
                approvalPending={approvalPending}
                setApprovalPending={setApprovalPending}
                transactionPending={transactionPending}
                setTransactionPending={setTransactionPending}
              />
            }
          />
          <Route path="/" element={<Navigate to="/unstaked"/>}/>
        </Routes>
        <Terms />
      </div>
    </>
  );
};

export default App;
