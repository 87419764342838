import React from "react";
import {useState, useEffect} from "react";
import "./connect.scss";
import {FaWallet} from "react-icons/fa";
import {ethers, providers} from "ethers";
import {toast, ToastContainer} from "react-toastify";
import configs from "../../utils/config.json"
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import {
  getCollectionsName,
  providerHandler,
} from "../../web3/contractInteraction";

const Connect = ({
                   setProvider,
                   provider,
                   toastOptions,
                   userAddress,
                   setUserAddress,
                   loading,
                   selected,
                   setSelected,
                   handleStake,
                   handleStakeAll,
                   handleUnstakeAll,
                   handleUnstake,
                   nfts,
                   passes,
                   setAllCollections,
                   isApproved,
                   handleSetApproval,
                   setChainId,
                   approvalPending, transactionPending
                 }) => {
  const routeName = window.location.pathname;
  const [web3Modal, setWeb3Modal] = useState(null);

  useEffect(() => {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        network: "mainnet",
        options: {
          //infuraId: process.env.REACT_APP_INFURAID,
          infuraId: configs.infuraId,          
          rpcUrl: "https://mainnet.infura.io/v3/"
        },
      },
    };

    const newWeb3Modal = new Web3Modal({
      cacheProvider: false,
      network: "mainnet",
      providerOptions,
    });

    setWeb3Modal(newWeb3Modal);
  }, []);

  async function addListeners(web3ModalProvider) {
    web3ModalProvider.on("accountsChanged", (account) => {
      setUserAddress(account[0]);
    });

    web3ModalProvider.on("chainChanged", (chainId) => {
      const configChainId = configs.ETHChainID;
      setChainId(chainId);
      if (chainId !== null) {
        if (chainId !== `0x${configChainId}`) {
          toast("Please connect to ETH mainnet.", toastOptions);
        }
      }
    });
  }

  async function connectWallet() {
    const provider = await web3Modal.connect();
    addListeners(provider);
    const ethersProvider = new providers.Web3Provider(provider);
    setProvider(ethersProvider);
    await providerHandler(ethersProvider);

    const userAddress = await ethersProvider.getSigner().getAddress();
    setUserAddress(userAddress);

    const chain = await ethersProvider.getNetwork();
    setChainId("0x" + chain.chainId);

    const allCollections = await getCollectionsName();
    setAllCollections(allCollections);
  }

  const disconnectWeb3Modal = async () => {
    await web3Modal.clearCachedProvider();
    setUserAddress();
  };

  return (
    <>
      <div className="connect_main_div">
        <div className="header_div">

          {userAddress &&
          <div className='wallet_address_div'>
            <FaWallet className="wallet_logo"/>
            {userAddress.slice(0, 5) + "...." + userAddress.slice(-4)}
          </div>
          }

          {userAddress !== undefined &&
          <button disabled={loading} onClick={disconnectWeb3Modal} className='disconnect_btn'>Disconnect</button>}
        </div>
        <div className="details_div">
          {userAddress === undefined && (
            <div className="connect_btn_div">
              <button onClick={connectWallet} className="connect_btn">
                Connect
              </button>
            </div>
          )}
          {userAddress !== undefined && isApproved === true && selected.length === 0 && (
            <div className="selectAll_div">
              {transactionPending ?
                <p className="select_text">Transaction pending... </p>
                :
                <>
                  <p className="select_text">
                    Select NFTs to {routeName === "/unstaked" && "stake"}{" "}
                    {routeName === "/staked" && "unstake"} or...
                  </p>
                  {routeName === "/unstaked" && (
                    <button
                      disabled={nfts.length === 0 || loading}
                      className="stakeAll"
                      onClick={handleStakeAll}
                    >
                      Stake All
                    </button>
                  )}
                  {routeName === "/staked" && (
                    <button
                      disabled={passes.length === 0 || loading}
                      className="stakeAll"
                      onClick={handleUnstakeAll}
                    >
                      Unstake All
                    </button>
                  )}
                </>
              }
            </div>
          )}
          {
            !isApproved && userAddress !== undefined && <div className="selectAll_div">
              <p
                className="approve_text">{approvalPending ? 'Approval pending...' : routeName === '/unstaked' ? 'Please allow the staking contract to manage your NFTs.' : 'The staking contract also needs to be approved.'}</p>
              {!approvalPending &&
              <button onClick={handleSetApproval} disabled={loading} className='approve'>Approve</button>}
            </div>
          }
          {userAddress !== undefined && isApproved === true && selected.length !== 0 && (
            <div className="double_btn_div">
              {transactionPending ?
                <p className="select_text">Transaction pending...</p>
                :
                <>
                  {routeName === "/unstaked" && (
                    <button
                      disabled={loading}
                      onClick={handleStake}
                      className="stake"
                    >
                      Stake
                    </button>
                  )}
                  {routeName === "/staked" && (
                    <button
                      disabled={loading}
                      onClick={handleUnstake}
                      className="stake"
                    >
                      Unstake
                    </button>
                  )}
                  <button
                    disabled={loading}
                    className="cancel"
                    onClick={() => setSelected([])}
                  >
                    Cancel
                  </button>
                </>
              }
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Connect;
