import {ethers, utils} from "ethers";
import configs from '../utils/config.json';
import stakingAbi from "./stakingAbi.json";
// import passAbi from "./passAbi.json";
// import nftAbi from "./nftAbi.json";
// all staking passes are the same. only import 1 passAbi
import passAbi from "./pass1Abi.json";
// NFT ABI's are different. import different ABI for each
import nft1Abi from "./nft1Abi.json";
import nft2Abi from "./nft2Abi.json";
import nft3Abi from "./nft3Abi.json";
import {Contract, Provider} from "ethers-multicall";

let stakeContract;
let passContract1;
let passContract2;
let passContract3;
let nftContract1;
let nftContract2;
let nftContract3;

export const providerHandler = async (provider) => {
  const signer = provider.getSigner();
  stakeContract = new ethers.Contract(configs.stakingAddress, stakingAbi, signer);

  passContract1 = new ethers.Contract(configs.pass1Address, passAbi, signer);
  passContract2 = new ethers.Contract(configs.pass2Address, passAbi, signer);
  passContract3 = new ethers.Contract(configs.pass3Address, passAbi, signer);

  nftContract1 = new ethers.Contract(configs.nft1Address, nft1Abi, signer);
  nftContract2 = new ethers.Contract(configs.nft2Address, nft2Abi, signer);
  nftContract3 = new ethers.Contract(configs.nft3Address, nft3Abi, signer);
  // return address;
};

export const totalSupply = async (collectionValue) => {
  let n;
  if (collectionValue === '1') {
    n = await nftContract1.totalSupply()
  } else if (collectionValue === '2') {
    n = await nftContract2.totalSupply()
  } else if (collectionValue === '3') {
    n = await nftContract3.totalSupply()
  }
  return n.toNumber()
}

export const getImages = async (collectionValue, id) => {
  let n;
  if (collectionValue === '1') {
    n = await nftContract1.tokenURI(id)
  } else if (collectionValue === '2') {
    n = await nftContract2.tokenURI(id)
  } else if (collectionValue === '3') {
    n = await nftContract3.tokenURI(id)
  }
  return n
}

export const getImagesPass = async (collectionValue, id) => {
  let n;
  if (collectionValue === '1') {
    n = await passContract1.tokenURI(id)
  } else if (collectionValue === '2') {
    n = await passContract2.tokenURI(id)
  } else if (collectionValue === '3') {
    n = await passContract3.tokenURI(id)
  }
  return n
}

export const getCollectionsName = async () => {
  try {
    const nftName1 = await nftContract1.name()
    const nftName2 = await nftContract2.name()
    const nftName3 = await nftContract3.name()
    const passName1 = await passContract1.name()
    const passName2 = await passContract2.name()
    const passName3 = await passContract3.name()
    return [nftName1, nftName2, nftName3, passName1, passName2, passName3]
  } catch (e) {
    return false;
  }
}

export const balanceOfstaked = async (collectionValue, userAddress) => {
  let n;
  if (collectionValue === '1') {
    n = await passContract1.balanceOf(userAddress)
  } else if (collectionValue === '2') {
    n = await passContract2.balanceOf(userAddress)
  } else if (collectionValue === '3') {
    n = await passContract3.balanceOf(userAddress)
  }
  return n
}

let address;

export async function nftcall(provider, collectionValue, totalSupply) {
  try {
    const ethCallProvider = new Provider(provider);
    const account = await provider.listAccounts();
    address = account[0];
    await ethCallProvider.init();
    let multiCallContract;
    if (collectionValue === '1') {
      multiCallContract = new Contract(configs.nft1Address, nft1Abi);
    } else if (collectionValue === '2') {
      multiCallContract = new Contract(configs.nft2Address, nft2Abi);
    } else if (collectionValue === '3') {
      multiCallContract = new Contract(configs.nft3Address, nft3Abi);
    }
  
    let call_array = []
    for (let i = 0; i < totalSupply; i++) {
      const call = multiCallContract.ownerOf(i);
      call_array.push(call)
    }
  
    const rec_call = await ethCallProvider.all(call_array);
  
    let nft_array = [];
    for (let i = 0; i < totalSupply; i++) {
      if (rec_call[i] === address) {
        nft_array.push(i)
      }
    }
    if (nft_array.length !== 0) {
      return nft_array
    } else {
      return []
    }
  } catch (error) {
    console.log("NFT CALL ERROR");
    return []
  }
}

export const tokenOfOwnerByIndex = async (collectionValue, userAddress, indexId) => {
  let n;
  if (collectionValue === '1') {
    n = await passContract1.tokenOfOwnerByIndex(userAddress, indexId)
  } else if (collectionValue === '2') {
    n = await passContract2.tokenOfOwnerByIndex(userAddress, indexId)
  } else if (collectionValue === '3') {
    n = await passContract3.tokenOfOwnerByIndex(userAddress, indexId)
  }
  return n.toNumber()
}

export const stakeKitties = async (collectionAddress, tokenIds) => {
  try {
    // console.log("STAKE:", collectionAddress, tokenIds)
    const n = await stakeContract.stakeTokens(collectionAddress, tokenIds);
    await n.wait()
    return n
  } catch (e) {
    return e.message.split("(")[0]
  }
}

export const unstakeKitties = async (collectionAddress, tokenIds) => {
  try {
    const n = await stakeContract.unstakeTokens(collectionAddress, tokenIds);
    await n.wait()
    return n
  } catch (e) {
    return e.message.split("(")[0]
  }
}

export const isApprovedForAll = async (collectionValue, account) => {
  let n;
  if (collectionValue === '1') {
    n = await nftContract1.isApprovedForAll(account, configs.stakingAddress);
  } else if (collectionValue === '2') {
    n = await nftContract2.isApprovedForAll(account, configs.stakingAddress);
  } else if (collectionValue === '3') {
    n = await nftContract3.isApprovedForAll(account, configs.stakingAddress);
  }
  return n
}

export const setApprovalForAll = async (collectionValue) => {
  try {
    let n;
    if (collectionValue === '1') {
      n = await nftContract1.setApprovalForAll(configs.stakingAddress, true);
    } else if (collectionValue === '2') {
      n = await nftContract2.setApprovalForAll(configs.stakingAddress, true);
    } else if (collectionValue === '3') {
      n = await nftContract3.setApprovalForAll(configs.stakingAddress, true);
    }
    await n.wait()
    return n
  } catch (e) {
    return false
  }
}

export const isApprovedForAllStaked = async (collectionValue, account) => {
  let n;
  if (collectionValue === '1') {
    n = await passContract1.isApprovedForAll(account, configs.stakingAddress);
  } else if (collectionValue === '2') {
    n = await passContract2.isApprovedForAll(account, configs.stakingAddress);
  } else if (collectionValue === '3') {
    n = await passContract3.isApprovedForAll(account, configs.stakingAddress);
  }
  return n
}

export const setApprovalForAllStaked = async (collectionValue) => {
  try {
    let n;
    if (collectionValue === '1') {
      n = await passContract1.setApprovalForAll(configs.stakingAddress, true);
    } else if (collectionValue === '2') {
      n = await passContract2.setApprovalForAll(configs.stakingAddress, true);
    } else if (collectionValue === '3') {
      n = await passContract3.setApprovalForAll(configs.stakingAddress, true);
    }
    await n.wait()
    return n
  } catch (e) {
    return false
  }
}

export const getStakingTime = async (collectionValue, tokenIds) => {

  try {
    let n;
    if (collectionValue === '1') {
      n = await passContract1.getStakingTime(tokenIds);
    } else if (collectionValue === '2') {
      n = await passContract2.getStakingTime(tokenIds);
    } else if (collectionValue === '3') {
      n = await passContract3.getStakingTime(tokenIds);
    }
    return n
  } catch (e) {
    return false
  }
}

export const getNapTime = async (collectionValue, tokenIds) => {
  try {
    // Init multicall
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const ethcallProvider = new Provider(provider);
    await ethcallProvider.init();

    // Init contract
    const contract = new Contract(configs.stakingAddress, stakingAbi);

    // Create multicall array
    let multicallArr = [];
    for (let i = 0; i < tokenIds.length; i++) {
      const n = await contract.stakeTotalTime(configs[`nft${collectionValue}Address`], tokenIds[i]);
      multicallArr.push(n);
    }

    // Get responses and format
    let resultArr = await ethcallProvider.all(multicallArr);
    for (let j = 0; j < resultArr.length; j++) {
      resultArr[j] = {
        current: resultArr[j].current.toNumber(),
        total: resultArr[j].total.toNumber()
      }
    }

    return resultArr;
  } catch (e) {
    console.log(e);
    return false
  }
}