import React, {useEffect, useState} from "react";
import "./unstaked.scss";
import Logo from "../../components/logo/Logo";
import Collection from "../../components/collection/Collection";
import Connect from "../../components/connect/Connect";
import Navbar from "../../components/navbar/Navbar";
import SingleCard from "../../components/singleCard/SingleCard";
import configs from "../../utils/config.json";
import {
  isApprovedForAll,
  setApprovalForAll,
  stakeKitties,
  nftcall,
  totalSupply
} from "../../web3/contractInteraction";
import loader from "../../assets/loader.gif";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Unstaked = ({
                    setProvider,
                    provider,
                    toastOptions,
                    userAddress,
                    setUserAddress,
                    allCollections,
                    collectionValue,
                    selected,
                    setSelected,
                    setCollectionValue,
                    nfts,
                    passes,
                    loading,
                    setLoading,
                    setAllCollections,
                    chainId,
                    setChainId,
                    handleSetCollections,
                    approvalPending,
                    setApprovalPending,
                    setTransactionPending,
                    transactionPending,
                    getAllTokens
                  }) => {
  const [isApproved, setIsApproved] = useState(false);

  useEffect(() => {
    setSelected([])
    handleSetCollections()
    if (userAddress) {
      handleApprovalCheck()
      getAllTokens()
    }
  }, [userAddress, collectionValue, chainId]);


  const handleApprovalCheck = async () => {
    const _isApproved = await isApprovedForAll(collectionValue, userAddress);
    setIsApproved(_isApproved)
  }

  const handleStake = async () => {
    setLoading(true);
    setTransactionPending(true)
    let res;
    if (collectionValue === "1") {
      res = await stakeKitties(configs.nft1Address, selected);
    } else if (collectionValue === "2") {
      res = await stakeKitties(configs.nft2Address, selected);
    } else if (collectionValue === "3") {
      res = await stakeKitties(configs.nft3Address, selected);
    }
    setTransactionPending(false)
    getAllTokens()
    setSelected([]);
    if (res?.hash) {
      toast("Your transaction was successful!", toastOptions);
    } else {
      if(res === 'user rejected transaction '){
        toast('You rejected the transaction.', toastOptions);
      }else{
        toast('Sorry, something went wrong.', toastOptions);
      }
    }
  };

  const handleStakeAll = async () => {
    setLoading(true);
    setTransactionPending(true)
    const tokenIds = nfts.map(nft => nft.id);
    let res;
    if (collectionValue === "1") {
      res = await stakeKitties(configs.nft1Address, tokenIds);
    } else if (collectionValue === "2") {
      res = await stakeKitties(configs.nft2Address, tokenIds);
    } else if (collectionValue === "3") {
      res = await stakeKitties(configs.nft3Address, tokenIds);
    }
    setTransactionPending(false)
    getAllTokens()
    setSelected([]);
    if (res?.hash) {
      toast("Your transaction was successful!", toastOptions);
    } else {
      if(res === 'user rejected transaction '){
        toast('You rejected the transaction.', toastOptions);
      }else {
        toast('Sorry, something went wrong.', toastOptions);
      }
    }
  };

  const handleSetApproval = async () => {
    setLoading(true)
    setApprovalPending(true)
    const approval = await setApprovalForAll(collectionValue);
    setApprovalPending(false)
    setLoading(false)
    handleApprovalCheck()
  }

  return (
    <>
      <ToastContainer/>
      <div className="middle_main_div">
        <div className="left_column_div">
          {/* <Logo/> */}
          {userAddress !== undefined && (
            <Collection
              allCollections={allCollections}
              collectionValue={collectionValue}
              setCollectionValue={setCollectionValue}
              loading={loading}
            />
          )}
          <Connect
            setProvider={setProvider}
            provider={provider}
            toastOptions={toastOptions}
            userAddress={userAddress}
            setUserAddress={setUserAddress}
            loading={loading}
            passes={passes}
            nfts={nfts}
            handleStakeAll={handleStakeAll}
            handleStake={handleStake}
            setSelected={setSelected}
            selected={selected}
            setAllCollections={setAllCollections}
            chainId={chainId}
            setChainId={setChainId}
            isApproved={isApproved}
            handleSetApproval={handleSetApproval}
            approvalPending={approvalPending}
            transactionPending={transactionPending}
          />
        </div>
        <div className="right_column_div">
          <Navbar userAddress={userAddress} nfts={nfts} passes={passes}/>
          <div className="card_display_div">
            {loading ? (
              <div className="loader_img_div">
                <img src={loader} alt="loading..."/>
              </div>
            ) : (
              <div
                className='card_scroll_div'
              >
                {userAddress !== undefined
                  ? nfts.length > 0 ? nfts.map((token,i) => (
                    <SingleCard
                      key={i}
                      userAddress={userAddress}
                      token={token}
                      selected={selected}
                      setSelected={setSelected}
                      collectionValue={collectionValue}
                    />
                  )) : <div className="loader_img_div">
                    No NFTs here
                  </div>
                  : <div className="loader_img_div">
                    Connect your wallet to see your NFTs
                  </div>
                }
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Unstaked;
