import React, {useEffect} from "react";
import "./collection.scss";

const Collection = ({
                      collectionValue,
                      setCollectionValue,
                      allCollections,
                      loading
                    }) => {
  const routeName = window.location.pathname;
  useEffect(() => {
    localStorage.setItem("collection", JSON.stringify("1"));
  }, []);

  const handleChange = (e) => {
    setCollectionValue(e.target.value);
    localStorage.setItem("collection", JSON.stringify(e.target.value));
  };

  return (
    <>
      <div className="collection_main_div">
        <p className="header">Collections</p>
        <div className="collection_container">
          <div className="collection_name" >
            <input
              type="radio"
              id="1"
              checked={collectionValue === "1"}
              name="collection"
              value="1"
              onChange={(e) => handleChange(e)}
              className="radio_btn"
              disabled={loading}
            />
            {allCollections.length !== 0 ? (
              (routeName === "/unstaked" && (
                <label htmlFor="1">
                  <div className="img-container">
                    <img alt="" src="https://gateway.pinata.cloud/ipfs/QmYwGuBeRhvyi6F2U4AEUxuTdZ3u7qer37vNx2roSMTkQk"/>
                  </div>
                  <span>{allCollections[0]}</span>
                </label>
              )) ||
              (routeName === "/staked" && (
                <label htmlFor="1">
                  <div className="img-container">
                    <img alt="" src="https://gateway.pinata.cloud/ipfs/QmYwGuBeRhvyi6F2U4AEUxuTdZ3u7qer37vNx2roSMTkQk"/>
                  </div>
                  <span>{allCollections[0]}</span>
                </label>
              ))
            ) : (
              <label htmlFor="1">loading...</label>
            )}
          </div>
          <div className="collection_name">
            <input
              type="radio"
              id="2"
              checked={collectionValue === "2"}
              name="collection"
              value="2"
              onChange={(e) => handleChange(e)}
              className="radio_btn"
              disabled={true}
            />
            {allCollections.length !== 0 ? (
              (routeName === "/unstaked" && (
                <label htmlFor="2">
                  <div className="img-container">
                    <img alt="" src="https://gateway.pinata.cloud/ipfs/QmfChPzR9XawJtsnFM36RP9YUirKSTPQgWWe91hjudeGoQ"/>
                  </div>
                  <span>{allCollections[1]}</span>
                </label>
              )) ||
              (routeName === "/staked" && (
                <label htmlFor="2">
                  <div className="img-container">
                    <img alt="" src="https://gateway.pinata.cloud/ipfs/QmfChPzR9XawJtsnFM36RP9YUirKSTPQgWWe91hjudeGoQ"/>
                  </div>
                  <span>{allCollections[1]}</span>
                </label>
              ))
            ) : (
              <label htmlFor="2">loading...</label>
            )}
          </div>
          <div className="collection_name">
            <input
              type="radio"
              id="3"
              checked={collectionValue === "3"}
              name="collection"
              value="3"
              onChange={(e) => handleChange(e)}
              className="radio_btn"
              disabled={true}
            />
            {allCollections.length !== 0 ? (
              (routeName === "/unstaked" && (
                <label htmlFor="3">
                  <div className="img-container">
                    <img alt="" src="https://gateway.pinata.cloud/ipfs/QmVoVyLnC4j3qUUaSGS3vcSGqsPpEKbrDFzKALdmzuNyDu"/>
                  </div>
                  <span>{allCollections[2]}</span>
                </label>
              )) ||
              (routeName === "/staked" && (
                <label htmlFor="3">
                  <div className="img-container">
                    <img alt="" src="https://gateway.pinata.cloud/ipfs/QmVoVyLnC4j3qUUaSGS3vcSGqsPpEKbrDFzKALdmzuNyDu"/>
                  </div>
                  <span>{allCollections[2]}</span>
                </label>
              ))
            ) : (
              <label htmlFor="3">loading...</label>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Collection;
